<div id="transfer-dialog" class="p-12">
	<h1>Ceder evaluación</h1>

	<!-- Search -->
	<div class="fullwidth" style="position: relative; margin-top: 5px">
		<mat-form-field subscriptSizing="dynamic" class="search-user w-95-p">
			<input
				matInput
				placeholder="Busca a la persona que quieres ceder la evaluación..."
				[(ngModel)]="filterPersonValue"
				(keyup)="filterPersons($event)"
			/>
			<mat-icon *ngIf="!personSelected" matSuffix class="s-18">search</mat-icon>
			<button *ngIf="filterPersonValue.length > 0" matSuffix mat-icon-button aria-label="Clear" (click)="clearPerson()">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>

		<div *ngIf="persons.length > 0 && !personSelected" class="wrap-persons">
			<div class="list-persons">
				<div *ngFor="let person of persons" fxlayout="row" fxLayoutAlign="flex-start center" class="item-person" (click)="selectPerson(person)">
					<div class="person-avatar avatar-50 mr-8" fxLayout="row" fxLayoutAlign="center center">
						<img class="img-circle" [src]="getImagePerson(person.username)" [title]="person.username" />
					</div>
					<div fxLayout="column" class="info-person">
						<span class="m-0">{{ "@" + person.username }}</span>
						<span class="m-0"
							><strong>{{ person.displayName }}</strong></span
						>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div fxLayout="row wrap" fxLayoutAlign="space-between center">
		<button *ngIf="data.transferedTo" class="action-button" mat-button aria-label="Clear" (click)="removeTransfer()">Eliminar cesión</button>
		<button
			class="action-button"
			mat-button
			aria-label="Clear"
			[ngClass]="!personSelected ? 'disabled' : null"
			[disabled]="!personSelected"
			(click)="transfer()"
		>
			Ceder evaluación
		</button>
	</div>
</div>
